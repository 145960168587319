.app-welcome-screen-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: #c42131;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 19px;
  
  &:hover {
    color: #fff;
  }
}

.app-welcome-screens {
  position: fixed;
  left: 25px;
  bottom: 25px;
  z-index: 1000;

  .app-welcome-screen {   
    display: none; 
    align-items: end;
  
    &.active {   
      display: flex; 

      @media (max-width: 575.98px) {
        flex-direction: column;
      }
    }

    &.ready {
      .app-welcome-screen-content {
        display: block;
      }
    }
  
    .app-welcome-screen-content {
      display: none;
      --r: 25px; /* the radius */
      --t: 30px; /* the size of the tail */
      --_d: 0%;
      position: relative;
      
      max-width: 300px;
      padding: calc(2 * var(--r)/3);
      -webkit-mask: 
        radial-gradient(var(--t) at var(--_d) 0,#0000 98%,#000 102%) var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat,
        conic-gradient(at var(--r) var(--r),#000 75%,#0000 0) calc(var(--r)/-2) calc(var(--r)/-2) padding-box, 
        radial-gradient(50% 50%,#000 98%,#0000 101%) 0 0/var(--r) var(--r) space padding-box;
      background: #fff border-box;
      color: black;
      border-left: var(--t) solid #0000;
      // margin-right: var(--t);
      place-self: start;
      box-shadow: 0 0 15px rgba(0,0,0,.3);
      // animation: crescendo 1.2s alternate 1s 6 ease-in;
      animation: bounce-in 1s ease 1;
      // animation: hithere 1s ease 1;

      @media (max-width: 575.98px) { 
        max-width: calc(100vw - 50px);
        background-color: #eee;
        border: none;
        -webkit-mask: none;
        border-radius: 8px;
      }
    
      h2 {
        margin: 0;
      }
    
      p:last-child {
        margin-bottom: 0;
      }

      .small-flags {
        display: flex;

        .flag {
          &:first-child {
            margin-left: 0px;
          }

          width: 20px;
          cursor: pointer;
          margin-left: 5px;
        }
      }
    }  
  
    .app-welcome-screen-image {
      position: relative;
      flex: 1 0 100px;
    
      img.contact-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0,0,0,.3);
      }
      
      img.flag {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      @media (max-width: 575.98px) {
        order: 2;
        align-self: flex-start;
        margin-top: 20px;
      }
    }
  }  
}

@keyframes crescendo {
  0%   {transform: scale(1.0);}
  100% {transform: scale(.9);}
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}

@keyframes hithere {
  30% { transform: scale(1.1); }
  40%, 60% { transform: rotate(-20deg) scale(1.1); }
  50% { transform: rotate(20deg) scale(1.1); }
  70% { transform: rotate(0deg) scale(1.1); }
  100% { transform: scale(1); }
}